import "./CouponsTC.css"
import React from "react"

export default class CouponsTC extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            coupon: props.coupon,
            accepted: false
        }

        this.toggleAccept = this.toggleAccept.bind(this)
    }

    toggleAccept() {
        this.setState({ ...this.state, accepted: !this.state.accepted })
    }

    render() {
        return (
            <div className="content step3" id="redeem-step3">
                {(() => {
                    if (this.state.coupon) {
                        return (
                            <>
                                <img className="coupon-image" src={this.state.coupon.image_url} alt='' />
                                <div className="line"></div>
                                <h4>Terms & Conditions</h4>
                                <div className="tc">{this.state.coupon.terms_conditions}</div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="redeem-tc" onChange={this.toggleAccept} />
                                    <label className="form-check-label" htmlFor="redeem-tc">
                                        I accept the terms and conditions
                                    </label>
                                </div>
                                <button id="proceed3" className="btns" onClick={() => { this.props.onNext(this.state.coupon) }} disabled={!this.state.accepted}>Continue</button>
                            </>
                        )
                    }
                })()}
                <button id="refer-back" onClick={() => { this.props.onBack(this.state.coupon) }} className="btns">Back</button>
            </div>
        )
    }
}