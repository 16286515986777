import Coupon from "../coupon/Coupon"
import CouponCode from "../couponCode/CouponCode"
import CouponsTC from "../couponsTC/CouponsTC"
import Redeem from "../redeem/Redeem"
import "./RedeemModule.css"
import React from "react"

export default class RedeemModule extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentScreen: 1,
            coupon: undefined
        }

        this.goToNext = this.goToNext.bind(this)
        this.goBack = this.goBack.bind(this)
        this.goHome = this.goHome.bind(this)
    }

    goToNext(coupon) {
        let nextScreen = this.state.currentScreen + 1
        this.setState({ ...this.state, currentScreen: nextScreen, coupon: coupon })
    }

    goBack(coupon) {
        let prevScreen = this.state.currentScreen - 1
        this.setState({ ...this.state, currentScreen: prevScreen, coupon: coupon })
    }

    goHome() {
        let homeScreen = 1
        this.setState({ ...this.state, currentScreen: homeScreen })
    }

    render() {
        return (
            <div className="modal fade" id="PointsEarned" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="PointsEarned" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content points-earned">
                        <a href="!#" className="close-btn" data-bs-dismiss="modal" aria-label="Close">
                            <img src="assets/img/close-btn.svg" alt='' />
                        </a>
                        <div className="pop-banner points-earned">
                            <h1>REDEEM</h1>
                        </div>
                        <div className="modal-body">
                            {(() => {
                                if (this.state.currentScreen === 1) {
                                    return <Redeem onNext={this.goToNext} />
                                } else if (this.state.currentScreen === 2) {
                                    return <Coupon onNext={this.goToNext} />
                                } else if (this.state.currentScreen === 3) {
                                    return <CouponsTC onNext={this.goToNext} onBack={this.goBack} coupon={this.state.coupon} />
                                } else if (this.state.currentScreen === 4) {
                                    return <CouponCode coupon={this.state.coupon} onHome={this.goHome} />
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}