/* eslint-disable jsx-a11y/anchor-has-content */
import { EventEmitter } from "../../events"
import InnovationsModel from "../../model/InnovationsModel"
import "./InnovationForm.css"
import React from "react"
import AWS from "aws-sdk"
import TermsAndConditions from "./termsAndConditions/termsAndConditions"

export default class InnovationForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activity: props.innovation,
            title: "",
            details: "",
            url: "",
            start_date: "",
            end_date: "",
            hasAccepted: false,
            bgClass: "",
            submitted: false,
            file: {},
            fileSelected: false,
            fileUploaded: false,
            fileUploadKey: Math.random().toString(36),
            showTC: false,
        }

        this.submitInnovation = this.submitInnovation.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.toggleTCView = this.toggleTCView.bind(this)
        this.innovationModel = new InnovationsModel()
    }

    componentDidMount() {
        let bgClass = this.getInnovationClass(this.props.innovation_count)

        this.setState({ ...this.state, bgClass: bgClass })
    }

    getInnovationClass(count) {
        let className
        switch (count) {
            case 1:
                className = "bg-pink"
                break
            case 2:
                className = "bg-blue"
                break
            case 3:
                className = "bg-yellow"
                break
            default:
                className = ""
                break
        }

        return className
    }

    handleChange(event) {
        let { name, value } = event.target
        if (name === "hasAccepted") {
            if (event.target.checked) {
                value = true
            } else {
                value = false
            }
            this.setState({ ...this.state, hasAccepted: value })
        } else if (name === "title") {
            // Validate title length before updating state
            if (value.length <= 50) {
                this.setState({ ...this.state, [name]: value, titleError: null });
            } else {
                this.setState({ ...this.state, titleError: "Title cannot exceed 50 characters" });
            }
        } else if (name === "details") {
            // Validate details length before updating state
            if (value.length <= 2000) {
                this.setState({ ...this.state, [name]: value });
            }
        } else if (name === "url") {
            // Validate details length before updating state
            if (value.length <= 255) {
                this.setState({ ...this.state, [name]: value, URLError: null });
            } else {
                this.setState({ ...this.state, URLError: "URL cannot exceed 255 characters" });
            }
        } else {
            this.setState({ ...this.state, [name]: value })
        }
    }

    toggleTCView() {
        this.setState({ ...this.state, showTC: !this.state.showTC })
    }

    async submitInnovation(event) {
        event.preventDefault()

        let submittedInnovation = await this.innovationModel.SetInnovation(
            this.state.activity.id,
            this.state.title,
            this.state.details,
            this.state.url,
            this.state.start_date,
            this.state.end_date,
        )
        this.setState({ ...this.state, fileUploadKey: Math.random().toString(36) })

        EventEmitter.dispatch("coinUpdated", {
            userPoints: this.state.activity.submission_prize
        })

        if (submittedInnovation.message === "111") {
            let newState = {
                activity: this.state.activity,
                title: "",
                details: "",
                url: "",
                start_date: "",
                end_date: "",
                hasAccepted: false,
                bgClass: this.state.bgClass,
                submitted: true
            }
            this.setState(newState, () => {
                setTimeout(() => {
                    this.setState({ ...this.state, submitted: false })
                }, 5000)
            })
        }
    }

    async uploadFile() {
        // S3 Bucket Name
        const S3_BUCKET = "video.stogoworld.com";

        // S3 Region
        const REGION = "ap-south-1";

        // S3 Credentials
        AWS.config.update({
            accessKeyId: "AKIA2IIRR47UDZ7NU363",
            secretAccessKey: "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",
        });
        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        // Files Parameters

        let time = new Date().getTime()
        let filename = time + this.state.file.name
        const params = {
            Bucket: S3_BUCKET,
            Key: filename,
            Body: this.state.file,
        };

        // Uploading file to s3

        var upload = s3
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                // File uploading progress
                console.log(
                    "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            })
            .promise();

        await upload.then((err, data) => {
            // Fille successfully uploaded
            // alert("File uploaded successfully.")

            let link = `https://s3.ap-south-1.amazonaws.com/video.stogoworld.com/${filename}`
            this.setState({ ...this.state, url: link, fileUploaded: true })
        });
    }

    handleFileChange(e) {
        // Uploaded file
        const file = e.target.files[0]
        // Changing file state
        this.setState({ ...this.state, file: file, fileSelected: true })
    }

    render() {
        return (
            <>
                <form className={this.state.bgClass} onSubmit={this.submitInnovation} style={{ display: this.state.showTC ? 'none' : 'block' }}>
                    {(() => {
                        if (this.state.submitted) {
                            return (
                                <div className="alert alert-success" role="alert">
                                    Submitted
                                </div>
                            )
                        }
                    })()}
                    <div className="mb-3">
                        <label htmlFor="eventTitle" className="form-label">Event Title <span className="text-danger">{this.state.titleError}</span></label>
                        <input type="text" className="form-control" id="eventTitle" name="title" placeholder="Enter Event Title"
                            value={this.state.title} onChange={this.handleChange} required />

                    </div>
                    <div className="mb-3">
                        <label htmlFor="eventDetails" className="form-label">Event Details <span className="character-count">({this.state.details.length}/2000)</span></label>
                        <textarea className="form-control" id="eventDetails" rows="3"
                            placeholder="Enter Event Details"
                            name="details" value={this.state.details} onChange={this.handleChange} required></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="eventURL" className="form-label">
                            Please upload the video
                            {/* <a href="!#" data-bs-toggle="tooltip" data-bs-title="Drive URL Only"><i className="fa fa-info-circle" aria-hidden="true"></i></a> */}
                            <span className="text-danger">{this.state.URLError}</span>
                        </label>
                        <input type="hidden" className="form-control" id="eventURL" placeholder="Enter Drive URL For Video"
                            name="url" value={this.state.url} onChange={this.handleChange}
                            required />
                        <div className="input-group mb-3">
                            <input type="file" accept=".avi, .wmv, .mov, .flv, .3gpp, .webm, .DNxHR, .ProRes, .CineForm, .HEVC" className="form-control" key={this.state.fileUploadKey} onChange={this.handleFileChange} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" disabled={!this.state.fileSelected} hidden={this.state.fileUploaded} onClick={this.uploadFile}>Upload</button>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="Period" className="form-label">Period of Occurrence</label>
                        <div className="fourty">
                            <div className="input-group">
                                <input type="date" className="form-control" id="start_date"
                                    name="start_date" value={this.state.start_date} onChange={this.handleChange}
                                    required />
                            </div>
                        </div>
                        <div className="twenty text-center">
                            <p className="my-2">to</p>
                        </div>
                        <div className="fourty">
                            <div className="input-group">
                                <input type="date" className="form-control" id="end_date"
                                    name="end_date" value={this.state.end_date} onChange={this.handleChange}
                                    required />
                            </div>
                        </div>
                    </div>
                    <div className="form-group tc">
                        <input type="checkbox" className="form-check-input" id="hasAccepted" name="hasAccepted" value={this.state.hasAccepted} onChange={this.handleChange} />
                        <label className="form-check-label" htmlFor="hasAccepted">&nbsp;</label>
                        <span className="link" onClick={this.toggleTCView}>Terms & Conditions</span>
                    </div>
                    <button type="submit" className="button" disabled={!this.state.hasAccepted || !this.state.fileUploaded}>Submit</button>
                </form>

                <div className={this.state.bgClass} style={{ display: this.state.showTC ? 'block' : 'none' }}>
                    <TermsAndConditions termsAndConditions={this.state.activity.terms_conditions} onHide={this.toggleTCView}></TermsAndConditions>
                </div>
            </>
        )
    }
}