import "./readComics.css"
import React from "react"
import Swiper from "swiper"
import { Navigation, EffectFade } from 'swiper/modules'
import "swiper/css"
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import Modal from 'react-bootstrap/Modal'

export default class ReadComics extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showComics: false
        }

        this.toggleReadComic = this.toggleReadComic.bind(this)
    }

    componentDidUpdate() {
        new Swiper(".read-comics-swiper", {
            slidesPerView: 1,
            effect: "fade",
            modules: [Navigation, EffectFade],
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        });
    }

    toggleReadComic() {
        this.setState({ ...this.state, showComics: !this.state.showComics })
    }

    render() {
        return (
            <>
                <div className="content">
                    {/* <div style={{ display: this.state.showComics ? "none" : "block" }}> */}
                    <div>
                        <div className="left">
                            <img alt="" src="assets/img/read-comics-img.svg" />
                        </div>
                        <div className="right space">
                            <span className="orange">read</span><span className="brown">comic</span>
                            <h2 onClick={this.toggleReadComic}>The STOGO Mission #1</h2>
                        </div>
                    </div>

                    <Modal
                        show={this.state.showComics}
                        onHide={() => this.toggleReadComic()}
                        dialogClassName="modal-90w"
                        backdrop="static"
                        keyboard={false}
                        size='xl'
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                STOGO Comic
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <div style={{ display: this.state.showComics ? "block" : "none" }}> */}
                            <div>
                                {/* <button type="button" className="read-comics-goback" onClick={this.toggleReadComic}>Go Back</button> */}
                                <div className="swiper read-comics-swiper">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/1.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/2.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/3.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/4.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/5.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/6.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/7.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/8.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/9.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/10.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/11.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/12.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/13.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/14.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/15.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/16.webp" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="assets/img/comics/17.webp" alt="" />
                                        </div>
                                    </div>
                                    <div className="swiper-button-next"></div>
                                    <div className="swiper-button-prev"></div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>



                </div>
            </>
        )
    }
}