import "./termsAndConditions.css"
import React from "react"
import GoBackButton from "./../../common/goBackButton/GoBackButton"

export default class TermsAndConditions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            termsAndConditions: props.termsAndConditions
        }
    }

    render() {
        return (
            <div>
                <h5>
                    <GoBackButton onClick={this.props.onHide}></GoBackButton>
                    Terms And Conditions
                </h5>
                <div dangerouslySetInnerHTML={{ __html: this.state.termsAndConditions }} />
            </div>
        )
    }
}