import "./Quiz.css"
import React from "react"
import Question from "./question/Question"
import QuizModel from "../../../model/QuizModel"
import UserModel from "../../../model/UserModel"
import ContestModel from "../../../model/ContestModel"
import { EventEmitter } from "../../../events"

export default class Quiz extends React.Component {
    constructor(params) {
        super(params)
        this.state = {
            contest: params.contest,
            questions: [],
            currentIndex: 0,
            score: 0,
            timeTaken: 0
        }
        this.quizModel = new QuizModel()
        this.userModel = new UserModel()
        this.contestModel = new ContestModel()
        this.changeCurrentIndex = this.changeCurrentIndex.bind(this)
    }

    componentDidMount() {
        this.fetchQuestionsByContest()
    }

    componentWillUnmount() {
        this.setState({})
    }

    async fetchQuestionsByContest() {
        const response = await this.quizModel.GetContestQuestions(this.state.contest.id)
        this.setState({
            contest: this.state.contest,
            questions: response.data,
            currentIndex: this.state.currentIndex,
            score: 0,
            timeTaken: 0
        })
    }

    async changeCurrentIndex(questionAttributes) {
        const idx = this.state.currentIndex + 1
        let scoreUpdate = this.state.score
        let timeTaken = this.state.timeTaken
        if (questionAttributes.isRightAnswer) {
            scoreUpdate++
            timeTaken += questionAttributes.timeTaken
        }
        let newQuestions = this.state.questions
        newQuestions[this.state.currentIndex].isRightAnswer = questionAttributes.isRightAnswer
        newQuestions[this.state.currentIndex].selectedAnswer = questionAttributes.selectedAnswer
        newQuestions[this.state.currentIndex].usedTime = questionAttributes.timeTaken
        if (this.state.questions.length > idx) {
            this.setState({
                questions: newQuestions,
                contest: this.state.contest,
                currentIndex: idx,
                score: scoreUpdate,
                timeTaken: timeTaken
            })
        } else {
            this.setState({
                questions: this.state.questions,
                contest: this.state.contest,
                currentIndex: this.state.currentIndex,
                score: scoreUpdate,
                timeTaken: timeTaken
            })
            // Calculate user score
            let userScore = this.userModel.CalculateScore(this.state.score, this.state.questions.length)
            // Calculate user points
            let price = parseInt(this.state.contest.entry)
            let prize = parseInt(this.state.contest.entry_prize)
            let fullMarksPrize = parseInt(this.state.contest.full_mark_prize)
            let userPoints = 0
            if (this.state.currentIndex === this.state.score) {
                userPoints = fullMarksPrize + prize - price
            } else {
                userPoints = prize - price
            }

            await this.userModel.SetUserCoinScore(userPoints, userScore, null, "ContestPlay Quiz Win", 1)
            EventEmitter.dispatch("coinUpdated", {
                price: price,
                prize: prize,
                fullMarksPrize: fullMarksPrize,
                userPoints: userPoints
            })
            let percentage = (100 * userScore) / this.state.questions.length
            await this.userModel.SetUserStatistics(this.state.questions.length, this.state.score, null, percentage)
            await this.contestModel.SetContestLeaderBoard(this.state.contest.id, this.state.questions.length, this.state.score, userScore, this.state.timeTaken)

            EventEmitter.dispatch("quizAttended")
            let statistics = {
                totalQuestions: this.state.questions.length,
                correctAnswers: this.state.score,
                timeTaken: this.state.timeTaken,
                questions: this.state.questions,
            }
            this.props.onClick(statistics)
        }
    }

    render() {
        if (this.state.questions.length > 0) {
            let totalQuestions = this.state.questions.length
            let pos = this.state.currentIndex + 1
            let remaining = totalQuestions - pos
            let question = this.state.questions[this.state.currentIndex]
            return (
                <Question key={question.id}
                    onClick={this.changeCurrentIndex}
                    question={question}
                    contest={this.state.contest}
                    position={pos}
                    remaining={remaining}></Question>
            )
        } else {
            return
        }
    }
}