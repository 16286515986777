import ConfigModel from "./ConfigModel";
import UserModel from "./UserModel";

export default class StudentSubmissionModel {
    configModel
    userModel
    constructor() {
        this.configModel = new ConfigModel()
        this.userModel = new UserModel()
    }

    async GetStudentSubmission() {
        let url = `${this.configModel.api}get_submissions_for_student`
        let data = {
            access_key: "8525"
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            if (!response_data.error) {
                return response_data.data
            } else {
                return []
            }
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }

    }

    async GetAcceptedStudentSubmissions() {
        let url = `${this.configModel.api}get_accepted_student_submissions`
        let data = {
            access_key: "8525"
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            if (!response_data.error) {
                return response_data.data
            } else {
                return []
            }
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }
}