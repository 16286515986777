import "./ContestLeaderBoard.css"
import React from "react"
import LeaderBoardModel from "../../../model/LeaderBoardModel"

export default class ContestLeaderBoard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            contest: props.contest,
            leaderBoard: []
        }

        this.leaderBoardModel = new LeaderBoardModel()

        this.fetchContestLeaderBoard = this.fetchContestLeaderBoard.bind(this)
    }

    componentDidMount() {
        this.fetchContestLeaderBoard()
    }

    async fetchContestLeaderBoard() {
        const leaderBoard = await this.leaderBoardModel.GetContestLeaderBoard(this.state.contest.id)
        this.setState({ ...this.state, leaderBoard: leaderBoard })
    }

    getClassByIndex(index) {
        if (index % 4 === 0) {
            return "yellow highlight"
        } else if (index % 4 === 1) {
            return "blue highlight"
        } else if (index % 4 === 2) {
            return "red highlight"
        } else {
            return "brown highlight"
        }
    }

    render() {
        return (
            <div className="leader-board" style={{ background: "rgb(255 255 255 / 0%)" }}>
                <h5 className="mb-3 ps-1">{this.state.contest.name} - Leader board</h5>

                <ul>
                    {this.state.leaderBoard.map((userRank, index) => (
                        <li key={index} className={this.getClassByIndex(index)}>
                            <img alt="" className="pos-ico" src={`assets/img/pos${index + 1}-ico.svg`} />
                            <img alt="" className="user-ico" src="assets/img/lb-user-ico.svg" />
                            <p className="username">{userRank.name}</p>
                            <div className="score">
                                <img alt="" src="assets/img/lb-coin-icon.svg" />
                                <span>{userRank.score}</span>
                            </div>
                        </li>
                    ))}

                </ul>

                <button type="button" className="play-btn mt-5" onClick={this.props.onHideLeaderBoard}>Go Back</button>
            </div>
        )
    }
}