import './Contest.css'
import React from "react"
import Button from './Button'

export default class Contest extends React.Component {
    constructor(param) {
        super(param)
        this.state = {
            contest: param.contest,
            type: param.type
        }
    }

    componentWillUnmount() {
        this.setState({})
    }

    render() {
        let str = (
            <>
                <div className="contest swiper-slide">
                    <img style={{ height: '190px' }} src={this.state.contest.image} alt={this.state.contest.name} />
                    <div className="cont">
                        <h3>{this.state.contest.name}</h3>
                        <p><span>Participation Prize:</span> {this.state.contest.entry_prize}</p>
                        <p><span>Participants: </span> {this.state.contest.participants}</p>
                        <p><span>Start Date:</span> {this.state.contest.start_date}</p>
                        <p><span>End Date:</span> {this.state.contest.end_date}</p>
                        <p><span>Age Group:</span> 6-8 yrs</p>
                        <h5><a href="contestsTC.html" target='_blank'>Terms & Conditions</a></h5>
                        <Button onClick={() => this.props.onClick(this.state.contest)} type={this.state.type}></Button>
                    </div>
                </div>
            </>

        )

        return str
    }
}