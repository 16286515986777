import "./Innovation.css"
import React from "react"

export default class Innovation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            count: props.count,
            innovation: props.innovation,
            id: props.innovation.id,
            title: props.innovation.name,
            description: props.innovation.description,
            start_date: props.innovation.start_date,
            end_date: props.innovation.end_date
        }
    }

    componentDidMount() {
        var myCollapsible = document.getElementById(`collapse${this.state.count}`)

        let args = {
            count: this.state.count,
            innovation: this.state.innovation
        }

        if (myCollapsible.classList.contains("show")) {
            this.props.onClick(args)
        }

        myCollapsible.addEventListener('shown.bs.collapse', () => {
            this.props.onClick(args)
        })
    }

    render() {
        return (
            <div className="accordion-item">
                <h2 className="accordion-header" id={`heading${this.state.count}`}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target={`#collapse${this.state.count}`} aria-expanded="true" aria-controls={`collapse${this.state.count}`}>
                        {this.state.innovation.name}
                    </button>
                </h2>
                <div id={`collapse${this.state.count}`} className={`accordion-collapse collapse ${this.state.count === 1 ? 'show' : ''}`}
                    aria-labelledby={`heading${this.state.count}`} data-bs-parent="#accordionInnovations">
                    <div className="accordion-body">
                        <div dangerouslySetInnerHTML={{ __html: this.state.innovation.description }} />
                        <br /><br />
                        <b>Start Date:</b> {this.state.innovation.start_date.split(" ")[0]}<br />
                        <b>End Date:</b> {this.state.innovation.end_date.split(" ")[0]}<br /><br />
                        {/* <i className="fa fa-arrow-right" aria-hidden="true"></i> */}
                        {/* <b><a className="steps-link" href="!#">Steps to Follow</a></b> */}
                    </div>
                </div>
            </div>
        )
    }
}