import "./QuizResult.css"
import React from "react"
import Chart from 'chart.js/auto'

export default class QuizResult extends React.Component {
    constructor(props) {
        super(props)
        let minutes = Math.floor(props.summary.timeTaken / 60)
        this.state = {
            timeTaken: props.summary.timeTaken,
            minutes: minutes,
            seconds: props.summary.timeTaken - (minutes * 60),
            totalQuestions: props.summary.totalQuestions,
            correctAnswers: props.summary.correctAnswers,
            incorrectAnswers: props.summary.totalQuestions - props.summary.correctAnswers,
            percentage: ((props.summary.correctAnswers / props.summary.totalQuestions) * 100).toFixed(2)
        }
    }

    componentDidMount() {
        this.initChart()
    }

    componentWillUnmount() {
        this.setState({})
    }

    initChart() {
        let data = {
            datasets: [{
                data: [this.state.percentage, 100 - this.state.percentage],
                backgroundColor: ["#2BBF79", "#F06550"],
            }]
        }

        let options = {
            responsive: true,
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            }
        }

        let ctx = document.getElementById("QuizPieChart").getContext('2d')

        // eslint-disable-next-line no-undef
        new Chart(ctx, {
            type: 'pie',
            data: data,
            options: options
        })
    }

    render() {
        return (
            <div id="result" className="quiz-play qresult">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="name">Result</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div id="chart-container">
                                <canvas id="QuizPieChart"></canvas>
                                <div id="result-percentage">{this.state.percentage}%</div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="result-container">
                                <p className="time yellow"><img alt="time" src="assets/img/time-ico.svg" />{this.state.minutes} minute {this.state.seconds} Seconds</p>
                                <p className="time red"><img alt="incorrect" src="assets/img/incorrect-ico.svg" />{this.state.incorrectAnswers} Incorrect Answers</p>
                                <p className="time green"><img alt="correct" src="assets/img/correct-ico.svg" />{this.state.correctAnswers} out of {this.state.totalQuestions} points</p>
                            </div>
                        </div>
                        <div className="spacer30"></div>
                        <button className="nxt-btn" id="back-to-contests" onClick={this.props.onShowContests}>BACK to contests</button>
                        <button className="nxt-btn" id="review" onClick={this.props.onReviewAnswers}>REVIEW ANSWERS</button>
                    </div>
                </div>
            </div>
        )
    }
}