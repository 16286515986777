import UserModel from "../../model/UserModel"
import "./Redeem.css"
import React from "react"

export default class Redeem extends React.Component {
  constructor(param) {
    super(param)
    this.state = {
      contest: param.contest,
      type: param.type,
      user: {}
    }

    this.userModel = new UserModel()
  }

  componentDidMount() {
    let user = this.userModel.GetUserFromLocal()
    this.setState({ ...this.state, user: user })
  }

  componentWillUnmount() {
    this.setState({})
  }

  render() {
    let str = (
      <>
        <div className="content step1" id="redeem-step1">
          <div className="left">
            <h5>Points Earned</h5>
            <div className="point-box">{this.state.user.data?.userProfileStatics?.coins}</div>
            {(() => {
              if (this.state.user.data?.userProfileStatics?.coins < 350) {
                /* <!--If point lesser than 5k display this section--> */
                return (
                  <div id="if-point-low">
                    <h2>YOU NEED TO EARN 350 POINTS TO REDEEM</h2>
                    <h3>THANK YOU. VISIT AGAIN</h3>
                  </div>
                )
              }
            })()}
          </div>
          <div className="right space">
            <img src="assets/img/points-coin.svg" alt='' />
          </div>
          <div className="row">
            <div className="col-md-12">
              {(() => {
                if (this.state.user.data?.userProfileStatics?.coins >= 350) {
                  return (
                    <button id="redeem-points" className="btns" onClick={this.props.onNext}>REDEEM POINTS</button>
                  )
                }
              })()}
              {/* <button id="refer-earn" className="btns">Refer & Earn</button> */}
            </div>
          </div>
        </div>
      </>

    )

    return str
  }
}