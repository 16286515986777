import React from "react";
import "./studentSubmission.css";
import StudentSubmissionModel from "../../model/SudentSubmissionModel";

export default class StudentSubmission extends React.Component {
  constructor(param) {
    super(param);
    this.state = {
      contest: param.contest,
      type: param.type,
      submissionModel: new StudentSubmissionModel(),
      submissionData: null, // State to store the fetched data
    };
  }

  componentDidMount() {
    // Call the function when the component mounts
    this.fetchStudentSubmissionData()
  }

  fetchStudentSubmissionData = async () => {
    try {
      // Call the function from the model
      const result = await this.state.submissionModel.GetStudentSubmission();
      // Update the state with the fetched data
      this.setState({ submissionData: result });
    } catch (error) {
      console.error("Error fetching student submission:", error);
    }
  };

  render() {
    const { submissionData } = this.state;

    let content = null;

    if (submissionData) {
      // If data is available, display it
      content = (
        <>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Acceptance Status</th>
                  <th scope="col">URL</th>
                </tr>
              </thead>
              <tbody>
                {submissionData.map((student, index) => (
                  <tr key={student.id} className={`${student.is_accepted === "1" ? "table-success" : ""}`}>
                    <th scope="row">{index + 1}</th>
                    <td>{student.title}</td>
                    <td>{student.start_date}</td>
                    <td>{student.end_date}</td>
                    <td>{student.is_accepted === "1" ? "Accepted" : "Pending"}</td>
                    <td>
                      <a href={student.url} target="_blank" rel="noreferrer">{student.url}</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    }

    return content;
  }
}
