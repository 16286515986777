import './PlayLearnEarn.css'
import React from "react"


export default class PlayLearnEarn extends React.Component {
  constructor(param) {
    super(param)
    this.state = {
      contest: param.contest,
      type: param.type
    }
  }

  componentWillUnmount() {
    this.setState({})
  }

  render() {
    let str = (
      <>
        <div className="left play-learn">
          <ul>
            <a href="https://scratch.mit.edu/projects/120738613/" target="_blank" rel="noreferrer">
              <li>
                <img alt="" src="assets/img/puzzle1.jpg" title='Dodge' />
                <p title='Dodge'>Dodge</p>
              </li>
            </a>
            <a href="https://www.jigsawplanet.com/?rc=play&pid=067f451c74d7" target="_blank" rel="noreferrer">
              <li>
                <img alt="" src="assets/img/puzzle2.jpg" title='Jig Saw' />
                <p title='Jig Saw'>Jig Saw</p>
              </li>
            </a>
            <a href="https://wordwall.net/resource/3045686" target="_blank" rel="noreferrer">
              <li>
                <img alt="" src="assets/img/puzzle3.jpg" title='Cross word' />
                <p title='Cross word'>Cross word</p>
              </li>
            </a>
            <a href="https://wordwall.net/resource/4325178" target="_blank" rel="noreferrer">
              <li>
                <img alt="" src="assets/img/puzzle4.jpg" title='Matching pair' />
                <p title='Matching pair'>Matching pair</p>
              </li>
            </a>
            <a href="https://wordwall.net/resource/22869075" target="_blank" rel="noreferrer">
              <li>
                <img alt="" src="assets/img/puzzle5.jpg" title='Flash Cards' />
                <p title='Flash Cards'>Flash Cards</p>
              </li>
            </a>
            <a href="https://wordwall.net/about/template/complete-the-sentence" target="_blank" rel="noreferrer">
              <li>
                <img alt="" src="assets/img/puzzle6.jpg" title='Complete the sentence' />
                <p title='Complete the sentence'>Complete the sentence</p>
              </li>
            </a>
            <a href="https://scratch.mit.edu/projects/17828009/" target="_blank" rel="noreferrer">
              <li>
                <img alt="" src="assets/img/puzzle7.jpg" title='Typing Master' />
                <p title='Typing Master'>Typing Master</p>
              </li>
            </a>
          </ul>
        </div>
      </>

    )

    return str
  }
}