import "./ContestsModule.css"
import Contests from "./contests/Contests"
import Quiz from "./quiz/Quiz"
import QuizResult from "./quizResult/QuizResult"
import QuizSummary from "./quizSummary/QuizSummary"
import ReviewAnswers from "./reviewAnswers/ReviewAnswers"
import React from "react"

export default class ContestsModule extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            state: "contest",
            contest: null,
            summary: {}
        }

        this.showSummary = this.showSummary.bind(this)
        this.playContest = this.playContest.bind(this)
        this.showResult = this.showResult.bind(this)
        this.showContests = this.showContests.bind(this)
        this.reviewAnswers = this.reviewAnswers.bind(this)
    }

    showSummary(contest) {
        this.setState({
            ...this.state,
            state: "quizSummary",
            contest: contest
        })
    }

    playContest(contest) {
        this.setState({
            ...this.state,
            state: "quiz",
            contest: contest
        })
    }

    showResult(summary) {
        if (summary === null) {
            summary = this.state.summary
        }
        this.setState({
            ...this.state,
            state: "quizResult",
            summary: summary,
        })
    }

    showContests(summary) {
        this.setState({
            ...this.state,
            state: "contest"
        })
    }

    reviewAnswers(summary) {
        this.setState({
            ...this.state,
            state: "quizReview"
        })
    }

    render() {

        return (
            <>
                {(() => {
                    if (this.state.state === "contest") {
                        return (
                            <Contests onShowSummary={this.showSummary} onGoBack={this.props.onGoBack} />
                        )
                    } else if (this.state.state === "quizSummary") {
                        return (
                            <QuizSummary contest={this.state.contest} onPlayContest={this.playContest} onGoBack={this.props.onGoBack} />
                        )
                    } else if (this.state.state === "quiz") {
                        return (
                            <Quiz contest={this.state.contest} onClick={this.showResult} />
                        )
                    } else if (this.state.state === "quizResult") {
                        return (
                            <QuizResult summary={this.state.summary} onShowContests={this.showContests} onReviewAnswers={this.reviewAnswers} />
                        )
                    } else if (this.state.state === "quizReview") {
                        return (
                            <ReviewAnswers summary={this.state.summary} contest={this.state.contest} onShowResult={this.showResult} />
                        )
                    }
                })()}
            </>
        )
    }
}
