import UserModel from "../../model/UserModel"
import "./Profile.css"
import React from "react"
import { EventEmitter } from "../../events"
import RedeemedCoupons from "./RedeemedCoupons/RedeemedCoupons"

export default class Profile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            user: undefined,
            innovation: {},
            showVideoPlayer: false,
        }

        this.userModel = new UserModel()

        this.refreshUser = this.refreshUser.bind(this)
        this.playVideo = this.playVideo.bind(this)
        this.closeVideo = this.closeVideo.bind(this)
    }

    componentDidMount() {
        this.refreshUser()

        EventEmitter.subscribe('profileUpdated', () => {
            this.refreshUser()
        })
    }

    async refreshUser() {
        let user
        user = await this.userModel.GetUser()
        this.setState({ user: user })
    }

    playVideo(innovation) {
        this.setState({ ...this.state, innovation: innovation, showVideoPlayer: true })
    }

    closeVideo() {
        this.setState({ ...this.state, showVideoPlayer: false })
    }

    calculateAge(dob) {
        let ageDifMs = Date.now() - new Date(dob);
        let ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    render() {
        let age = this.calculateAge(this.state.user?.data?.student_dob)

        return (
            <div className="modal fade" id="Profile" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                aria-labelledby="Profile" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content profile">
                        <a href="!#" className="close-btn" data-bs-dismiss="modal" aria-label="Close">
                            <img alt="" src="assets/img/close-btn.svg" />
                        </a>
                        <div className="pop-banner profile">
                            <h1>PROFILE</h1>
                        </div>
                        <div className="modal-body">
                            <div className="content">
                                {(() => {
                                    if (!this.state.showVideoPlayer) {
                                        return (
                                            <>
                                                <div className="left">
                                                    <div className="l-l">
                                                        <img alt="" src="assets/img/profile-avatar.jpg" />
                                                        {/* <img alt="" src={this.state.user?.data?.profile} /> */}

                                                        {/* <button className="btn"><img alt="" src="assets/img/edit-profile-ico.svg" />Edit Profile</button> */}
                                                    </div>
                                                    <div className="l-r">
                                                        <h1>{this.state.user?.data?.name}</h1>
                                                        <span>{age} Years</span>
                                                        {/* <span>5K Friends</span><span>1.4K Peers</span> */}
                                                        <div className="clearfix"></div>
                                                        {/* <button className="be-btn"><img alt="" src="assets/img/befriend-ico.svg" />Befriend</button>
                                                        <button className="pe-btn"><img alt="" src="assets/img/peers-ico.svg" />Peers</button> */}
                                                        <ul>
                                                            <li>
                                                                <h5>School Name</h5>
                                                                <p>{this.state.user?.data?.school_name}</p>
                                                            </li>
                                                            <li>
                                                                <h5>Class & Division</h5>
                                                                <p>{this.state.user?.data?.class_name}</p>
                                                            </li>
                                                            {/* <li>
                                                                <h5>STOGO Command</h5>
                                                                <p><a href="!#">#STEAM</a> <a href="!#">#SOLARpowered</a> <a href="!#">#UFO</a></p>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <ul>
                                                        {/* <li>
                                                            <h5>Challenges Accepted:</h5>
                                                            <p>Quiz Rating, Points Earned, Global Leader Position, School Leader Position, Fan
                                                                Rating, Fan Followers</p>
                                                        </li> */}
                                                        <li>
                                                            <h5>All Time Rank: <span className="wins">{this.state.user?.data?.userProfileStatics?.all_time_rank}</span></h5>
                                                        </li>
                                                        <li>
                                                            <h5>Coins Earned: <span className="point">{this.state.user?.data?.userProfileStatics?.coins}</span></h5>
                                                        </li>
                                                        <li>
                                                            <h5>All Time Score: <span className="score">{this.state.user?.data?.userProfileStatics?.credits}</span></h5>
                                                        </li>
                                                        {/* <li>
                                                            <h5>Leaderboard: <span className="leader">35</span></h5>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                                <div className="clearfix"></div>
                                                {/* <div className="challenge-box">
                                                    <h1>Challenge Box</h1>
                                                    <ProfileSlider onPlayVideo={this.playVideo} />
                                                </div> */}

                                                <RedeemedCoupons></RedeemedCoupons>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                                {/* <div className="clearfix"></div>
                                                <VideoPlayer innovation={this.state.innovation} onClose={this.closeVideo}></VideoPlayer> */}
                                            </>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}