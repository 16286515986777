import ConfigModel from "./ConfigModel"
import UserModel from "./UserModel"

export default class InnovationsModel {
    configModel
    userModel

    constructor() {
        this.configModel = new ConfigModel()
        this.userModel = new UserModel()
    }

    async GetInnovations() {
        let url = `${this.configModel.api}get_activities`
        let data = {
            "access_key": "8525",
            "language_id": "34",
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            if (!response_data.error) {
                return response_data.data
            } else {
                return []
            }
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async SetInnovation(activity_id, title, details, entered_url, start_date, end_date) {
        let url = `${this.configModel.api}submit_innovations`
        let data = {
            "access_key": "8525",
            "activity_id": activity_id,
            "title": title,
            "details": details,
            "url": entered_url,
            "start_date": start_date,
            "end_date": end_date,
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async GetSubmittedInnovations() {
        let url = `${this.configModel.api}get_submissions_for_student`
        let data = {
            "access_key": "8525"
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            if (!response_data.error) {
                return response_data.data
            } else {
                return []
            }
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }
}