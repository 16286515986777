import "./Question.css"
import React from "react"
import QuizModel from "./../../../../model/QuizModel"

export default class Question extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            question: props.question,
            contest: props.contest,
            position: props.position,
            remaining: props.remaining,
            time: 30,
            optionClass: "",
            selectedOption: "",
            rightAnswer: ""
        }
        this.interval = null
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
        this.quizModel = new QuizModel()
        this.correctSound = new Audio("/assets/sounds/correct.mp3")
        this.wrongSound = new Audio("/assets/sounds/wrong.mp3")
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            let newTime = this.state.time - 1
            this.setState({
                question: this.state.question,
                contest: this.state.contest,
                position: this.state.position,
                remaining: this.state.remaining,
                time: newTime,
                optionClass: "",
                selectedOption: "",
                rightAnswer: ""
            })
        }, 1000)
    }

    componentDidUpdate() {
        if (this.state.time <= 0) {
            clearInterval(this.interval)

            let rtnObj = {
                selectedAnswer: this.state.selectedOption,
                isRightAnswer: false,
                timeTaken: this.state.time
            }
            this.props.onClick(rtnObj)
        }
    }

    checkAnswer(event) {
        clearInterval(this.interval)
        let answer = event.target.value
        let decryptedAnswer = this.quizModel.decryptAnswer(this.state.question.answer)
        switch (decryptedAnswer) {
            case "a":
                decryptedAnswer = this.state.question.optiona
                break;
            case "b":
                decryptedAnswer = this.state.question.optionb
                break;
            case "c":
                decryptedAnswer = this.state.question.optionc
                break;
            case "d":
                decryptedAnswer = this.state.question.optiond
                break;
            default:
                decryptedAnswer = this.state.question.optione
                break;
        }
        let isRightAnswer = false
        // let optionClass
        if (answer === decryptedAnswer) {
            // optionClass = "correct"
            isRightAnswer = true
            // this.correctSound.play()
        } else {
            // optionClass = "wrong"
            // this.wrongSound.play()
        }
        // this.setState({
        //     ...this.state,
        //     optionClass: optionClass,
        //     rightAnswer: decryptedAnswer,
        //     selectedOption: answer
        // })

        let rtnObj = {
            selectedAnswer: answer,
            isRightAnswer: isRightAnswer,
            timeTaken: this.state.time
        }

        // setTimeout(() => {
        //     this.props.onClick(rtnObj)
        // }, 1000)

        this.props.onClick(rtnObj)
    }

    render() {
        return (
            <div className="quiz-play">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="name">{this.state.contest.name}</div>
                            <h2>Question {this.state.position}</h2>
                            <h3>{this.state.question.question}</h3>
                            <p>{this.state.question.note}</p>
                        </div>
                        <div className="col-md-3">
                            <div className="timer">
                                <img alt="timer" src="assets/img/timer-ico.svg" />
                                <div id="timer">{this.state.time} sec</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6" onChange={this.checkAnswer.bind(this)}>
                            <div className={`form-check answer ${this.state.selectedOption === this.state.question.optiona ? this.state.optionClass : ''} ${this.state.rightAnswer === this.state.question.optiona ? 'correct' : ''}`}>
                                <input className="form-check-input" type="radio" name="quizone" id={"quizone1-" + this.state.question.id} value={this.state.question.optiona} />
                                <label className="form-check-label" htmlFor={"quizone1-" + this.state.question.id}>
                                    {this.state.question.optiona}
                                </label>
                            </div>
                            <div className={`form-check answer ${this.state.selectedOption === this.state.question.optionb ? this.state.optionClass : ''} ${this.state.rightAnswer === this.state.question.optionb ? 'correct' : ''}`}>
                                <input className="form-check-input" type="radio" name="quizone" id={"quizone2-" + this.state.question.id} value={this.state.question.optionb} />
                                <label className="form-check-label" htmlFor={"quizone2-" + this.state.question.id}>
                                    {this.state.question.optionb}
                                </label>
                            </div>
                            <div className={`form-check answer ${this.state.selectedOption === this.state.question.optionc ? this.state.optionClass : ''} ${this.state.rightAnswer === this.state.question.optionc ? 'correct' : ''}`}>
                                <input className="form-check-input" type="radio" name="quizone" id={"quizone3-" + this.state.question.id} value={this.state.question.optionc} />
                                <label className="form-check-label" htmlFor={"quizone3-" + this.state.question.id}>
                                    {this.state.question.optionc}
                                </label>
                            </div>
                            <div className={`form-check answer ${this.state.selectedOption === this.state.question.optiond ? this.state.optionClass : ''} ${this.state.rightAnswer === this.state.question.optiond ? 'correct' : ''}`}>
                                <input className="form-check-input" type="radio" name="quizone" id={"quizone4-" + this.state.question.id} value={this.state.question.optiond} />
                                <label className="form-check-label" htmlFor={"quizone4-" + this.state.question.id}>
                                    {this.state.question.optiond}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6"> <img alt="Question" className="quiz-img" src={this.state.question.image} /></div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {/* <button id="next-btn" className="nxt-btn" onClick={this.checkAnswer.bind(this)} value="">NEXT</button> */}
                            <span className="ques-left">{this.state.remaining} Questions Left</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}