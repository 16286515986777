import "./StartPage.css"
import React from 'react'
import AgeEntry from "./ageEntry/AgeEntry"
import Help from "./help/help"

export default class StartPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            screen: 1
        }

        this.changeState = this.changeState.bind(this)
    }

    changeState() {
        this.setState({ ...this.state, screen: 2 })
    }

    render() {
        return (
            <>
                <div className="content" >
                    <div style={{ display: this.state.screen === 1 ? "block" : "none" }}>
                        <AgeEntry onAgeEntered={this.changeState}></AgeEntry>
                    </div>
                    {/* <!-- how to play section --> */}
                    <div id="howtoplay" style={{ display: this.state.screen === 2 ? "block" : "none" }}>
                        <Help></Help>
                        <div id="login-submit" className="btns" onClick={() => this.props.onProceed()}>Proceed</div>
                    </div>
                </div>
            </>
        )
    }
} 