import LeaderBoardModel from "../../model/LeaderBoardModel"
import "./LeaderBoardModule.css"
import React from "react"

export default class LeaderBoardModule extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            leaderBoard: [],
            currentUserRank: {}
        }

        this.leaderBoardModel = new LeaderBoardModel()

        this.fetchAllTimeLeaderBoard = this.fetchAllTimeLeaderBoard.bind(this)
        this.switchLeaderBoard = this.switchLeaderBoard.bind(this)
    }

    componentDidMount() {
        this.fetchAllTimeLeaderBoard()
    }

    async fetchAllTimeLeaderBoard() {
        const leaderBoard = await this.leaderBoardModel.GetAllTimeLeaderBoard()
        if (leaderBoard) {
            this.setState({
                leaderBoard: leaderBoard.other_users_rank,
                currentUserRank: leaderBoard.my_rank,
            })
        }
    }

    switchLeaderBoard(type) {
        let data = {
            currentUserRank: this.state.currentUserRank,
            leaderBoard: this.state.leaderBoard,
            type: type
        }

        this.props.onSwitch(data)
    }

    render() {
        return (
            <>
                <div className="points">
                    <a href="!#" data-bs-toggle="modal" data-bs-target="#LeaderBoard" onClick={() => { this.switchLeaderBoard("weekly") }}>
                        <div key={`weekly1`} className="but">weekly</div>
                    </a>
                    <a href="!#" data-bs-toggle="modal" data-bs-target="#LeaderBoard" onClick={() => { this.switchLeaderBoard("all_time") }}>
                        <div key={`alltimes1`} className="but">all times</div>
                    </a>
                    <ul>
                        <li className="player2" style={this.state.leaderBoard?.length <= 1 ? { visibility: 'hidden' } : {}}>
                            <span>2</span>
                            <img alt="" className="avatar2" src="assets/img/player.jpg" />
                            <div className="name">@{this.state.leaderBoard[1]?.name}</div>
                            <div className="point">{this.state.leaderBoard[1]?.score}</div>
                        </li>
                        <li className="player1" style={this.state.leaderBoard.length <= 0 ? { visibility: 'hidden' } : {}}>
                            <img alt="" className="crown" src="assets/img/crown.svg" />
                            <img alt="" className="avatar1" src="assets/img/player.jpg" />
                            <div className="name">@{this.state.leaderBoard[0]?.name}</div>
                            <div className="point">{this.state.leaderBoard[0]?.score}</div>
                        </li>
                        <li className="player3" style={this.state.leaderBoard.length <= 2 ? { visibility: 'hidden' } : {}}>
                            <span>3</span>
                            <img alt="" className="avatar3" src="assets/img/player.jpg" />
                            <div className="name">@{this.state.leaderBoard[2]?.name}</div>
                            <div className="point">{this.state.leaderBoard[2]?.score}</div>
                        </li>
                    </ul>
                    <div className="position-block">
                        <img alt="" src="assets/img/123-blocks.gif" />
                    </div>
                </div>
            </>
        )
    }
}