import ConfigModel from "./ConfigModel";
import UserModel from "./UserModel";

export default class ContestModel {
    configModel
    userModel
    constructor() {
        this.configModel = new ConfigModel()
        this.userModel = new UserModel()
    }

    async GetContests() {
        let url = `${this.configModel.api}get_contest`
        let data = {
            access_key: "8525",
            language_id: "34"
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }

    }

    async GetContestLeaderBoard(contestId) {
        let url = `${this.configModel.api}get_contest_leaderboard`
        let data = {
            "access_key": "8525",
            "language_id": "34",
            "contest_id": contestId
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async SetContestLeaderBoard(contest_id, question_attended, correct_answers, score, timeTaken) {
        let url = `${this.configModel.api}set_contest_leaderboard`
        let data = {
            "access_key": "8525",
            "language_id": "34",
            "contest_id": contest_id,
            "questions_attended": question_attended,
            "correct_answers": correct_answers,
            "score": score,
            "time_taken": timeTaken
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async GetContestInfo() {
        let url = `${this.configModel.api}get_contest_info`
        let data = {
            access_key: "8525"
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("get contest info - request failed ", err)
            return err
        }

    }
}