import InnovationsModel from "../../model/InnovationsModel"
import Innovation from "../innovation/Innovation"
import InnovationForm from "../innovationForm/InnovationForm"
import "./Innovations.css"
import React from "react"

export default class Innovations extends React.Component {
    innovationsModel

    constructor(props) {
        super(props)
        this.innovationsModel = new InnovationsModel()

        this.state = {
            innovations: [],
            innovation: null,
            innovation_count: 0
        }

        this.setSelectedInnovation = this.setSelectedInnovation.bind(this)
    }

    componentDidMount() {
        this.fetchInnovations()
    }

    setSelectedInnovation(args) {
        this.setState({ ...this.state, innovation: null, innovation_count: 0 })
        setTimeout(() => {
            this.setState({ ...this.state, innovation: args.innovation, innovation_count: args.count })
        }, 100)
    }

    async fetchInnovations() {
        let innovations = await this.innovationsModel.GetInnovations()
        this.setState({ ...this.state, innovations: innovations })
    }

    render() {
        return (
            <>
                <div className="left">
                    <div className="accordion custom-accordion" id="accordionInnovations">
                        {(() => {
                            let count = 0
                            let rows = []
                            for (const innovation of this.state.innovations) {
                                count++
                                rows.push(<Innovation key={innovation.id}
                                    count={count}
                                    innovation={innovation}
                                    onClick={this.setSelectedInnovation}></Innovation>)
                            }
                            return rows
                        })()}
                    </div>
                </div>

                {(() => {
                    if (this.state.innovation !== null) {
                        return (
                            <div className="right">
                                <InnovationForm innovation={this.state.innovation}
                                    innovation_count={this.state.innovation_count}></InnovationForm>
                            </div>
                        )
                    }
                })()}
            </>
        )
    }
}